import {
  navigateTo,
  useCookie,
  useRuntimeConfig,
  useNuxtApp,
  type UseFetchOptions,
} from "#app";
import { useUserStore,useDebounce} from "#imports";
import { ElMessage } from "element-plus";

const debounceMessage = useDebounce((msg) => {
  process.client && ElMessage.error(msg);
}, 1500, {
  leading: false,
  trailing: true,
});

export const useHttpRequest = <T = unknown>(
  url: string,
  opts: UseFetchOptions<T, unknown>
) => {
  const userStore = useUserStore();
  const token = userStore.getToken;
  const runtimeConfig = useRuntimeConfig();
  const nuxtApp = useNuxtApp();

  const defaultOptions: UseFetchOptions<unknown> = {
    // @ts-ignore
    baseURL: runtimeConfig.public.baseUrl,
    onRequest({ options }) {
      options.headers = (options.headers || {}) as { [key: string]: string };
      if (token) {
        options.headers.Authorization = token as string;
      }
    },
    onResponse({ response }) {
      if(response.status === 200){
        if(response._data.code !== 0 && response._data.code !== 200){
          debounceMessage(response._data.msg);
          if(response._data.code == 403){
            userStore.setToken("");
            // navigateTo("/login");
            nuxtApp.$router.push("/login");
          }
        }
      }
    },
    onResponseError({ response }) {
      debounceMessage(response._data.msg);
      // process.client && ElMessage.error(response._data.msg);
    },
  };

  return $fetch<T>(url, { ...defaultOptions, ...opts } as any);
};